import { useEffect, useRef } from "react";

const Canvas = (props) => {
	const canvasRef = useRef(null);

	const { setCanvasData } = props;

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		/**
		 * Style the Context
		 */
		context.strokeStyle = "#000000";
		context.lineWidth = 2;

		let flag = false;

		let prevX = 0;
		let prevY = 0;

		let currX = 0;
		let currY = 0;

		const isTouchEvent = (e) => {
			return e.type.match(/^touch/);
		};

		const draw = () => {
			context.beginPath();
			context.moveTo(prevX, prevY);
			context.lineTo(currX, currY);
			context.strokeStyle = "black";
			context.lineWidth = 2;
			context.stroke();
			context.closePath();
		};

		const updatePosition = (e) => {
			let rect = canvas.getBoundingClientRect();

			prevX = currX;
			prevY = currY;

			let scaleX = canvas.width / rect.width;
			let scaleY = canvas.height / rect.height;

			if (isTouchEvent(e)) {
				currX = (e.touches[0].clientX - rect.left) * scaleX;
				currY = (e.touches[0].clientY - rect.top) * scaleY;
			} else {
				currX = (e.clientX - rect.left) * scaleX;
				currY = (e.clientY - rect.top) * scaleY;
			}
		};

		const onDown = (e) => {
			e.preventDefault();

			flag = true;

			updatePosition(e);

			context.beginPath();
			context.fillStyle = "black";
			context.fillRect(currX, currY, 2, 2);
			context.closePath();
		};

		const onUp = (e) => {
			e.preventDefault();

			flag = false;

			setCanvasData(canvas.toDataURL("image/png"));
		};

		const onOut = (e) => {
			e.preventDefault();

			flag = false;
		};

		const onMove = (e) => {
			e.preventDefault();

			if (!flag) {
				return;
			}

			updatePosition(e);

			draw();
		};

		canvas.addEventListener("mousedown", onDown, false);
		canvas.addEventListener("mousemove", onMove, false);
		canvas.addEventListener("mouseup", onUp, false);
		canvas.addEventListener("mouseout", onOut, false);
	}, []);

	return <canvas className="bg-gray-100 bg-opacity-40 border border-gray-300 rounded-lg w-full h-64" ref={canvasRef} />;
};

export default Canvas;

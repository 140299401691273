import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";

import Canvas from "../components/Canvas";
import Button from "../components/Button";

import logo from "../storage/logo.png";

const OrderReceived = () => {
	const { orderKey } = useParams();

	const [orderData, setOrderData] = useState(null);
	const [canvasData, setCanvasData] = useState(null);

	/**
	 * Component initialized.
	 */
	useEffect(() => {
		if (orderKey) {
			fetchOrderData(orderKey);
		}
	}, [orderKey]);

	const fetchOrderData = (orderUid) => {
		let url = process.env.REACT_APP_API_URL + "order/" + orderUid;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					console.log("Error fetching order data", res);
					return;
				}

				const { data } = res;

				setOrderData(data);
			});
	};

	const onSave = () => {
		let url = process.env.REACT_APP_API_URL + "order/" + orderKey + "/approve";

		fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: "test",
				image: canvasData,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					console.log("Error saving canvas data", res);
					return;
				}

				setOrderData((prev) => {
					return {
						...prev,
						status: "Unpaid",
					};
				});
			});
	};

	if (!orderData) {
		return <div>Loading...</div>;
	}

	if (orderData && orderData.status !== "Disallowed") {
		let transaction;

		let { status } = orderData;

		if (status === "Unpaid") {
			transaction = orderData.unpaid_advance_transaction;
		} else if (status === "Partially Paid") {
			transaction = orderData.unpaid_full_transaction;
		}

		if (!transaction) {
			return <Navigate to={`/order/${orderKey}/thankyou`} replace={true} />;
		}

		return <Navigate to={`/checkout/${orderKey}/transaction/${transaction.uid}`} replace={true} />;
	}

	const renderSignatureCanvas = () => {
		return <Canvas setCanvasData={setCanvasData} />;
	};

	const renderPriceLabel = () => {
		let { discounted_price, total_price } = orderData;

		if (!discounted_price) {
			return <span>{total_price}₪</span>;
		}

		return (
			<span>
				<span className="text-gray-500 line-through">{total_price}₪</span>
				<span className="ml-2">{discounted_price}₪</span>
			</span>
		);
	};

	const renderDependantMessage = () => {
		const { student } = orderData;

		return (
			<div>
				<p className="text-lg font-light mb-4">
					שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
					<br />
					אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת אקשן על גלגלים.
				</p>
				<div className="mb-6">
					<table className="w-full table-auto">
						<thead>
							<tr>
								<th className="border border-gray-300 px-4 py-2">שם הילד/ה</th>
								<th className="border border-gray-300 px-4 py-2">קייטנה</th>
								<th className="border border-gray-300 px-4 py-2">עלות הרשמה</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="border border-gray-300 px-4 py-2">{student.first_name + " " + student.last_name}</td>
								<td className="border border-gray-300 px-4 py-2">{student.camp.name}</td>
								<td className="border border-gray-300 px-4 py-2">{renderPriceLabel()}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="block">
					על מנת לעבור לשלב הבא בהרשמה, עליכם לחתום כי הנכם מאשרים את רישום <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> בתיבת החתימה הדיגיטלית שמופיעה מטה:
				</p>
				<div className="mt-2">{renderSignatureCanvas()}</div>
			</div>
		);
	};

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<header className="absolute top-[-67.5px] w-full">
						<img src={logo} alt="logo" className="mx-auto" />
					</header>
					<div className="px-4">
						<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
						{renderDependantMessage()}
					</div>
					<div className="mt-4">
						<Button onClick={onSave}>שמור והמשך</Button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default OrderReceived;

import React from 'react';

const Button = ({ children, disabled, variant, className, onClick }) => {
    let classList = `rounded-3xl bg-blue-500 text-white`;

    switch (variant) {
        case 'small': {
            classList += ' py-2 px-4';
            break;
        }
        default: {
            classList += ' py-3 px-6';
            break;
        }
    }

    if (className) {
        classList += ' ' + className;
    }

    return (
        <button className={classList} disabled={disabled} onClick={onClick}>{children}</button>
    );
}

export default Button;
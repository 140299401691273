const StepTwo = ({ renderFormObject, renderFormControl, workersCommittees, savedData, onInputChange }) => {
	if (!savedData) {
		savedData = {};
	}

	/**
	 * Add a placeholder item to the beginning of the array, if not exists.
	 */
	if (workersCommittees[0]?.id !== null) {
		workersCommittees.unshift({
			id: null,
			name: "-",
		});
	}

	return (
		<div>
			<div className="grid grid-cols-2 gap-8 text-right mx-auto text-md w-full">
				<div className="col-span-1">
					{[
						{ name: "mother_name", label: "שם הורה 1", type: "text" },
						{ name: "father_name", label: "שם הורה 2", type: "text" },
						{ name: "email", label: "אימייל", type: "email" },
						{ name: "mother_phone", label: "טלפון של הורה 1", type: "phone", subtitle: "הודעות ועדכונים ישלחו למספר הטלפון של הורה 1 בלבד." },
						{ name: "father_phone", label: "טלפון של הורה 2", type: "phone" },
					].map((field, index) => {
						return renderFormObject(field, index);
					})}
				</div>
				<div className="col-span-1">
					{[
						{ name: "emergency_name", label: "שם למקרה חירום", type: "text" },
						{ name: "emergency_closeness", label: "קירבה למקרה חירום", type: "text" },
						{ name: "emergency_phone", label: "טלפון למקרה חירום", type: "phone" },
						// {
						// 	name: "workers-committee",
						// 	label: "ועד עובדים / מועדון",
						// 	type: "select",
						// 	options: [
						// 		{ value: false, label: "לא" },
						// 		{ value: true, label: "כן" },
						// 	],
						// },
					].map((field, index) => {
						return renderFormObject(field, index);
					})}
					{savedData["workers-committee"] &&
						renderFormControl({
							name: "workers-committee-id",
							label: "שם הועד / מועדון",
							type: "select",
							options: workersCommittees.map((item) => {
								return {
									label: item.name,
									value: item.id,
								};
							}),
						})}
				</div>
			</div>
		</div>
	);
};

export default StepTwo;

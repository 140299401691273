import React from 'react'

import background from '../storage/background-blur.png';

const Layout = (props) => {
    return (
        <div className='font-nunito bg-black min-h-screen h-auto w-full bg-no-repeat bg-cover' style={{ backgroundImage: `url('${background}')` }}>
            <div className="flex justify-center align-middle min-h-screen h-auto bg-black bg-opacity-30">
                {props.children}
            </div>
        </div>
    )
}

export default Layout;

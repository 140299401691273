import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// require("dotenv").config();

/**
 * Registration pages.
 */
import Registration from "./pages/Registration";

/**
 * Payment pages.
 */
import Checkout from "./pages/Checkout";
// import FullPayment from './pages/FullPayment';
import OrderReceived from "./pages/OrderReceived";
import OrderThankyou from "./pages/OrderThankyou";

/**
 * Thankyou pages.
 */
import WorkersCommitteeThankyou from "./pages/WorkersCommitteeThankyou";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Registration />} />
				<Route path="/order-received/:orderKey" element={<OrderReceived />} />
				<Route path="/checkout/:orderKey/transaction/:transactionKey" element={<Checkout />} />
				{/* <Route path='/thankyou/:transactionKey' element={<AdvancePayment />} /> */}
				<Route path="/order/:orderKey/thankyou" element={<OrderThankyou />} />
				<Route path="/workers-committee/:orderKey" element={<WorkersCommitteeThankyou />} />
				{/* <Route path='/advance-payment/:orderKey' element={<AdvancePayment />} /> */}
				{/* <Route path='/order/:orderKey' element={<FullPayment />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";

import logo from "../storage/logo.png";

const OrderThankyou = () => {
	const { orderKey } = useParams();

	const [orderData, setOrderData] = useState(null);

    const selectPaymentPreference = (preference) => {
		let url = process.env.REACT_APP_API_URL + "order/" + orderKey + "/create-transaction";

		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				preference,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					alert("error!");
					return;
				}

				setOrderData(res.data);
			});
	};

	/**
	 * Component initialized.
	 */
	useEffect(() => {
		const fetchOrderData = (orderUid) => {
			let url = process.env.REACT_APP_API_URL + "order/" + orderUid;

			fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then((res) => res.json())
				.then((res) => {
					if (!res.success) {
						alert("error!");
						return;
					}

					setOrderData(res.data);
				});
		};

		if (orderKey) {
			fetchOrderData(orderKey);
		}
	}, [orderKey]);

	if (!orderData) {
		return <div>Loading...</div>;
	}

	const renderPaymentMessage = () => {
		const { student } = orderData;

		if (orderData.unpaid_advance_transaction === null && orderData.unpaid_full_transaction === null && orderData.status === "Unpaid") {
			// No payments are done, and no payments are required (yet).
			return (
				<div>
					<p>
						באפשרותך לבחור האם לשלם את מלוא הסכום עבור הרישום למקדמה, או לשלם את דמי המקדמה בסך <strong className="font-bold">250₪</strong> בלבד לצורך רישום והבטחת מיקום.
					</p>

					<div className="grid lg:grid-cols-2 gap-8 mt-8">
						<button className="block w-full bg-gray-200 hover:bg-gray-300 text-black rounded-3xl shadow-lg font-bold text-xl" style={{ aspectRatio: "3/1" }} onClick={() => selectPaymentPreference("advance")}>
							תשלום מקדמה
							<br />
							<small>(250₪)</small>
						</button>
						<button className="block w-full bg-gray-200 hover:bg-gray-300 text-black rounded-3xl shadow-lg font-bold text-xl" style={{ aspectRatio: "3/1" }} onClick={() => selectPaymentPreference("full")}>
							<br />
							<small>({orderData.total_unpaid}₪)</small>
						</button>
					</div>
				</div>
			);
		} else if (orderData.unpaid_advance_transaction === null && orderData.unpaid_full_transaction === null && orderData.status === "Paid") {
			// All payments are done.
			return (
				<div>
					<p className="block">
						כל התשלומים הושלמו בהצלחה.
						<br />
						מספר הרישום של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> הוא {student.id}
					</p>
					<div className="mt-10 mb-4">
						<a href="/" className="rounded-3xl bg-blue-500 text-white py-3 px-6">
							חזרה לאתר
						</a>
					</div>
				</div>
			);
		} else if (orderData.unpaid_advance_transaction === null && orderData.unpaid_full_transaction !== null) {
			// Only full payment is required.
			if (orderData.paid > 0) {
				return (
					<div>
						<p>
							לפי הרישומים שלנו, עד כה השלמתם את תשלום המקדמה בלבד עבור הרישום לקייטנה.
							<br />
							על מנת להשלים את רישומו של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> עליכם להשלים את מלוא התשלום.
						</p>
						<span className="block mt-4 text-lg font-bold">יתרה לתשלום</span>
						<span className="block">{orderData.total_unpaid}₪</span>
						<div className="mt-6 mb-4">
							<Link to={"/checkout/" + orderData.uid + "/transaction/" + orderData.unpaid_full_transaction.uid} className="rounded-3xl bg-blue-500 text-white py-3 px-6">
								מעבר לתשלום
							</Link>
						</div>
					</div>
				);
			}

			return (
				<div>
					<p>
						לפי הרישומים שלנו, בחרתם לשלם את מלוא הסכום עבור הרישום לקייטנה.
						<br />
						על מנת להשלים את רישומו של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> עליכם להשלים את מלוא התשלום.
					</p>
					<span className="block mt-4 text-lg font-bold">יתרה לתשלום</span>
					<span className="block">{orderData.total_unpaid}₪</span>
					<div className="mt-6 mb-4">
						<Link to={"/checkout/" + orderData.uid + "/transaction/" + orderData.unpaid_full_transaction.uid} className="rounded-3xl bg-blue-500 text-white py-3 px-6">
							מעבר לתשלום
						</Link>
					</div>
				</div>
			);
		} else if (orderData.unpaid_advance_transaction !== null && orderData.unpaid_full_transaction === null) {
			// Only advance payment is required.
			return (
				<div>
					<p>
						לפי הרישומים שלנו, עד כה לא השלמתם את תשלום המקדמה עבור הרישום של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנה.
						<br />
						לתשומת ליבכם, על מנת לשריין את מיקומו של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> בקייטנה, עליכם להסדיר את תשלום המקדמה.
					</p>
					<div className="mt-6 mb-4">
						<Link to={"/checkout/" + orderData.uid + "/transaction/" + orderData.unpaid_advance_transaction.uid} className="rounded-3xl bg-blue-500 text-white py-3 px-6">
							מעבר לתשלום
						</Link>
					</div>
				</div>
			);
		}

		return (
			<div>
				<p>שגיאה בטופס הרישום. אנא צרו קשר עם הנהלת הקייטנה.</p>
			</div>
		);
	};

	const renderDependantMessage = () => {
		const { student } = orderData;

		return (
			<div>
				<p className="text-lg font-light mb-4">
					שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
					<br />
					אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת אקשן על גלגלים.
				</p>
				{renderPaymentMessage()}
			</div>
		);
	};

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<header className="absolute top-[-67.5px] w-full">
						<img src={logo} alt="logo" className="mx-auto" />
					</header>
					<div className="px-4">
						<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
						{renderDependantMessage()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default OrderThankyou;

import React from 'react'

import Button from './Button';

import logo from '../storage/logo.png';

const SelectCamp = ({ onCampSelected, onContinue, camps, selectedCamp }) => {
    return (
        <div className='max-w-md w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-opacity-30 bg-white text-white relative'>
            <header className='absolute top-[-67.5px] w-full'>
                <img src={logo} alt="logo" className='mx-auto' />
            </header>
            <strong className='text-2xl font-semibold mb-2 block'>בחר קייטנה</strong>
            <p className='text-md font-light mb-4'>לקהל לוקחותינו,<br />הקייטנה עובדת לפי מתווה משרד הבריאות והחינוך</p>
            <div>
                {
                    camps && camps.length ? (
                        <select className="px-4 py-2 rounded-full mb-2 text-black text-sm" onChange={onCampSelected}>
                            <option value="0">בחר/י קייטנה</option>
                            {
                                camps.map(camp => {
                                    return (
                                        <option key={camp.id} value={camp.id}>{camp.name}</option>
                                    )
                                })
                            }
                        </select>
                    ) : (
                        <div>Loading</div>
                    )
                }
            </div>
            {
                selectedCamp ? (
                    <div className="grid grid-cols-3 text-right mx-auto text-md max-w-[200px]">
                        <b className='col-span-2'>מחיר:</b>
                        <span className='col-span-1'>
                            {selectedCamp.discount_price ? (
                                <span>
                                    <del>₪{selectedCamp.price}</del>
                                    <span>₪{selectedCamp.discount_price}</span>
                                </span>
                            ) : (
                                <span>₪{selectedCamp.price}</span>
                            )
                            }
                        </span>
                        {
                            (selectedCamp.discount_price && selectedCamp.discount_price_ends_at) ? (
                                <b className='col-span-2'>המחיר בתוקף עד {selectedCamp.discount_price_ends_at}</b>
                            ) : null
                        }
                    </div>
                ) : null
            }
            <div className="mt-4">
                <Button onClick={onContinue} disabled={selectedCamp == null}>
                    המשך
                </Button>
            </div>
        </div>
    )
}

export default SelectCamp

const StepThree = ({ renderFormObject, onInputChange, savedData, cities }) => {
	let selectedCity = savedData && savedData.pickup_city;
	let selectedCityData = ((selectedCity && cities) || []).filter((c) => c.id === parseInt(selectedCity)).shift();
	let availableSchools = [];

	if (selectedCityData && selectedCityData.schools) {
		availableSchools = selectedCityData.schools;
	}

	return (
		<div>
			<div className="grid grid-cols-2 gap-8 text-right mx-auto text-md w-full">
				<div className="col-span-2">
					<div className="text-center">
						<span className="font-bold">תחנת איסוף</span>
					</div>
				</div>

				<div className="col-span-1">
					{[
						{
							name: "pickup_city",
							label: "עיר",
							type: "select",
							placeholder: "בחר את העיר שממנה נאסוף את " + savedData.first_name,
							options: typeof cities === "object" ? cities.map((c) => ({ value: c.id, label: c.name })) : [],
						},
						{
							name: "pickup_school",
							label: "בית ספר",
							type: "select",
							placeholder: "בחר/י את בית הספר שממנו נאסוף את " + savedData.first_name,
							options: typeof availableSchools === "object" ? availableSchools.map((c) => ({ value: c.id, label: c.name })) : [],
						},
					].map((field, index) => renderFormObject(field, index))}
				</div>

				<div className="col-span-1">
					{[
						{
							name: "pickup_type",
							label: "איסוף הילד/ה",
							type: "select",
							groupSize: 2,
							placeholder: false,
							options: [
								{ value: "", label: "בחר/י את אופן האיסוף" },
								{ value: "יאסף בליווי אדם מבוגר מטעם המשפחה", label: "יאסף בליווי אדם מבוגר מטעם המשפחה" },
								{ value: "ילדי חוזר הביתה בכוחות עצמו", label: "ילדי חוזר הביתה בכוחות עצמו" },
							],
						},
					].map((field, index) => {
						return renderFormObject(field, index);
					})}
					{savedData["pickup_type"] === "יאסף בליווי אדם מבוגר מטעם המשפחה" &&
						[
							{ name: "pickup_contact_name", label: "שם האדם שאוסף", type: "text", groupSize: 3 },
							{ name: "pickup_contact_phone", label: "טלפון האדם שאוסף", type: "text", groupSize: 3 },
							{ name: "pickup_contact_closeness", label: "קירבת האדם שאוסף", type: "text", groupSize: 3 },
						].map((field, index) => renderFormObject(field, index))}
				</div>
			</div>
			<div className="mt-0 text-right">
				<div className={`w-full text-black bg-opacity-20 p-7 rounded-3xl text-center ${savedData.pickup_agree ? "bg-green-400 border border-green-700" : "bg-red-400 border border-red-700"}`}>
					<strong className={`${savedData.pickup_agree ? "text-green-600" : "text-black"}`}>
						לתשומת ליבך: זוהי נקודת האיסוף של ילדך ולא ניתן לשנות אותה לאחר הבחירה.
						<br />
						על מנת להירשם עם חברים נוספים, על החברים לסמן נקודת איסוף זהה.
					</strong>
				</div>
				<div className="mt-2">
					{renderFormObject({
						type: "checkbox",
						name: "pickup_agree",
						label: "אני מאשר/ת את נקודת האיסוף שבחרתי",
						required: true,
						placeholder: false,
					})}
				</div>
			</div>
			<div className="mt-6 text-right">
				<label className="font-semibold text-right" htmlFor="comment">
					הערות נוספות
				</label>
				<div className="mt-2">
					<textarea id="comment" name="comment" value={savedData.comment} className="px-4 py-3 h-32 rounded-2xl w-full text-black text-sm" onChange={onInputChange}></textarea>
				</div>
				<span className="block text-sm font-bold text-black">* יש לרשום את שמות החברים איתם תרצו שילדכם יהיה באותו האוטובוס. נעשה את מיטב מאמצינו.</span>
			</div>
		</div>
	);
};

export default StepThree;

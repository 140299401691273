import React from "react";

const StudentInformationForm = ({ renderFormObject }) => {
	return (
		<div className="grid grid-cols-2 gap-8 text-right mx-auto text-md w-full">
			<div className="col-span-1">
				{[
					{ name: "first_name", label: "שם פרטי", type: "text" },
					{ name: "last_name", label: "שם משפחה", type: "text" },
					[
						{
							name: "grade",
							label: "עולה לכיתה",
							type: "select",
							placeholder: true,
							options: [
								{ value: 1, label: "א" },
								{ value: 2, label: "ב" },
								{ value: 3, label: "ג" },
								{ value: 4, label: "ד" },
								{ value: 5, label: "ה" },
								{ value: 6, label: "ו" },
								{ value: 7, label: "ז" },
							],
						},
						{
							name: "class_number",
							label: "מספר כיתה",
							type: "select",
							placeholder: true,
							options: [
								{ value: 1, label: 1 },
								{ value: 2, label: 2 },
								{ value: 3, label: 3 },
								{ value: 4, label: 4 },
								{ value: 5, label: 5 },
								{ value: 6, label: 6 },
								{ value: 7, label: 7 },
							],
						},
					],
					[
						{ name: "gov_id", label: "ת.ז", type: "text" },
						{ name: "birthdate", label: "תאריך לידה", type: "date" },
					],
				].map((field, index) => {
					return renderFormObject(field, index);
				})}
			</div>
			<div className="col-span-1">
				{[
					{ name: "address", label: "כתובת", type: "text" },
					[
						{
							name: "shirt_size",
							label: "מידת חולצה",
							type: "select",
							placeholder: true,
							options: [
								{ value: 10, label: 10 },
								{ value: 12, label: 12 },
								{ value: 14, label: 14 },
								{ value: 16, label: 16 },
								{ value: 18, label: 18 },
								{ value: "S", label: "S" },
								{ value: "M", label: "M" },
								{ value: "L", label: "L" },
								{ value: "XL", label: "XL" },
							],
						},
						{
							name: "gender",
							label: "מין",
							type: "select",
							placeholder: true,
							options: [
								{ value: "male", label: "זכר" },
								{ value: "female", label: "נקבה" },
							],
						},
					],
					{ name: "hmo", label: "קופת חולים", type: "text" },
				].map((field, index) => {
					return renderFormObject(field, index);
				})}
			</div>
		</div>
	);
};

export default StudentInformationForm;

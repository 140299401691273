import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import Layout from "../layouts/Layout";

import SelectCamp from "../components/SelectCamp";
import StudentInformationForm from "../components/Forms/StudentInformationForm";
import ContactInformationForm from "../components/Forms/ContactInformationForm";
import PickupInformationForm from "../components/Forms/PickupInformationForm";
import HealthInformationForm from "../components/Forms/HealthInformationForm";

import Button from "../components/Button";

/**
 * SASS
 */
import "../sass/registration.scss";

const Registration = () => {
	const [fieldsData, setFieldsData] = useState({});
	const [savedData, setSavedData] = useState(null);
	const [currentStep, setCurrentStep] = useState(null);

	const [selectedCamp, setSelectedCamp] = useState(null);

	const [invalidFields, setInvalidFields] = useState([]);

	const RegistrationSteps = {
		CAMP_SELECTION: 1,
		STUDENT_INFORMATION: 2,
		CONTACT_INFORMATION: 3,
		PICKUP_INFORMATION: 4,
		HEALTH_INFORMATION: 5,
		SUCCESS: 6,
	};

	const [phoneNumbers, setPhoneNumbers] = useState({});
	const phonePrefixes = ["050", "052", "053", "054", "055", "057", "058"];

	/**
	 * Load the selection fields from the server.
	 */
	useEffect(() => {
		let fetchRegisterFieldsAbortController = new AbortController();

		fetch(process.env.REACT_APP_API_URL + "registerFields", {
			method: "GET",
			signal: fetchRegisterFieldsAbortController.signal,
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					return;
				}

				setFieldsData(res.data);
			});

		/**
		 * Unmount method.
		 */
		return () => {
			fetchRegisterFieldsAbortController.abort();
		};
	}, []);

	/**
	 * Page is loaded
	 *
	 * load the previous selections from the sessionStorage.
	 * set the current registration step depending on the data
	 */
	useEffect(() => {
		let data = sessionStorage.getItem("aag-registration");

		if (!fieldsData) {
			console.log("no fields data");
			return;
		}

		if (data) {
			data = JSON.parse(data);

			setSavedData(data);

			initiateCurrentStep(data);

			if (data.camp_id) {
				onCampSelected(data.camp_id);
			}
		} else {
			/**
			 * No saved data loaded from the sessionStorage.
			 * Therefore, initiate the registration form into it's first step.
			 */
			setCurrentStep(1);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldsData]);

	/**
	 * Catches when the 'savedData' state changes.
	 */
	useEffect(() => {
		/**
		 * Save the 'savedData' to the sessionStorage, so we can load it later if needed.
		 * only saves if the 'savedData' is not null.
		 */
		if (savedData && savedData !== null) {
			sessionStorage.setItem("aag-registration", JSON.stringify(savedData));

			initPhoneNumbers();
		}
	}, [savedData]);

	/**
	 * Current step has been changed.
	 */
	useEffect(() => {
		/**
		 * Clear the sessionStorage from our data when the user reaches the thankyou page.
		 */
		if (currentStep === RegistrationSteps.SUCCESS) {
			sessionStorage.removeItem("aag-registration");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep]);

	/**
	 * Functions.
	 */
	const initiateCurrentStep = (data) => {
		let stepToSet = RegistrationSteps.CAMP_SELECTION;

		if (!data.camp_id) {
			stepToSet = RegistrationSteps.CAMP_SELECTION;
		} else if (data.camp_id && !data.student_id) {
			stepToSet = RegistrationSteps.STUDENT_INFORMATION;
		} else if (data.student_id && !data.contact_information) {
			stepToSet = RegistrationSteps.CONTACT_INFORMATION;
		} else if (data.student_id && !data.pickup_information) {
			stepToSet = RegistrationSteps.PICKUP_INFORMATION;
		} else if (data.student_id && !data.health) {
			stepToSet = RegistrationSteps.HEALTH_INFORMATION;
		} else if (data.student_id && data.health) {
			stepToSet = RegistrationSteps.SUCCESS;
		}

		setCurrentStep(stepToSet);
	};

	const onCampSelected = (evt) => {
		let campId;

		if (evt.target) {
			campId = evt.target.value;
		} else {
			campId = evt;
		}

		let camp = (fieldsData.camps || []).filter((camp) => parseInt(camp.id) === parseInt(campId)).shift();

		/**
		 * Make sure the discount price is not expired.
		 */
		if (camp && camp.discount_price && camp.discount_price_ends_at) {
			let discountPriceEndsAt = new Date(camp.discount_price_ends_at);

			if (discountPriceEndsAt < new Date()) {
				camp.discount_price = null;
			}
		}

		setSelectedCamp(camp);
	};

	const normalizeValue = (value) => {
		if (value === "true") {
			return true;
		} else if (value === "false") {
			return false;
		}

		return value;
	};

	const onInputChange = (evt) => {
		let name, value;

		if (evt.target) {
			name = evt.target.name;
			value = evt.target.value;

			if (evt.target.type === "checkbox") {
				value = evt.target.checked;
			}
		} else {
			name = evt.name;
			value = evt.value;
		}

		if (typeof name === "undefined") {
			console.error("unable to set value");

			return;
		}

		if (invalidFields.includes(name)) {
			setInvalidFields(invalidFields.filter((field) => field !== name));
		}

		value = normalizeValue(value);

		console.log("onInputChange", name, value);

		setSavedData({
			...savedData,
			[name]: value,
		});
	};

	const initPhoneNumbers = () => {
		const phoneFields = ["mother_phone", "father_phone", "emergency_phone"];
		/**
		 * Get the prefix and check if it's a valid one.
		 * If the prefix is valid, use the rest as the number.
		 */
		phoneFields.forEach((field) => {
			if (savedData && savedData.hasOwnProperty(field)) {
				let phonePrefix = savedData[field].substring(0, 3);
				let phoneNumber = savedData[field].substring(3);

				if (phonePrefixes.includes(phonePrefix)) {
					setPhoneNumbers((prev) => {
						return {
							...prev,
							[field]: {
								prefix: phonePrefix,
								number: phoneNumber,
							},
						};
					});
				}
			}
		});
	};

	const onPhoneNumberChange = (name, part, value) => {
		let current = phoneNumbers[name];

		if (!current) {
			current = {
				prefix: "",
				number: "",
			};
		}

		if (invalidFields.includes(name)) {
			setInvalidFields(invalidFields.filter((field) => field !== name));
		}

		if (part === "prefix") {
			current.prefix = value;
		} else if (part === "number") {
			current.number = value;
		}

		setPhoneNumbers({
			...phoneNumbers,
			[name]: current,
		});

		/**
		 * Save the phone number to the savedData state.
		 */
		setSavedData({
			...savedData,
			[name]: current.prefix + current.number,
		});
	};

	const getPhoneNumber = (name, part) => {
		if (!phoneNumbers) {
			return "";
		}

		if (phoneNumbers[name]) {
			return phoneNumbers[name][part];
		}
	};

	const prevStep = (evt) => {
		let setStep = currentStep;

		setStep--;

		if (setStep <= 0) {
			setStep = 0;
		}

		setCurrentStep(setStep);
	};

	const nextStep = (evt) => {
		switch (currentStep) {
			case RegistrationSteps.CAMP_SELECTION: {
				setSavedData({
					...savedData,
					camp_id: selectedCamp.id,
				});

				setCurrentStep(2);
				break;
			}
			case RegistrationSteps.STUDENT_INFORMATION:
			case RegistrationSteps.CONTACT_INFORMATION:
			case RegistrationSteps.PICKUP_INFORMATION:
			case RegistrationSteps.HEALTH_INFORMATION: {
				let apiEndpoint = "";
				let requiredFields = [];

				switch (currentStep) {
					case RegistrationSteps.STUDENT_INFORMATION: {
						apiEndpoint = process.env.REACT_APP_API_URL + "register1stStep";
						requiredFields = ["first_name", "last_name", "grade", "class_number", "gov_id", "birthdate", "address", "shirt_size", "gender", "hmo"];

						break;
					}

					case RegistrationSteps.CONTACT_INFORMATION: {
						apiEndpoint = process.env.REACT_APP_API_URL + "register2ndStep";
						requiredFields = ["mother_name", "father_name", "email", "emergency_phone", "emergency_closeness", "emergency_name", "mother_phone", "father_phone"];

						break;
					}

					case RegistrationSteps.PICKUP_INFORMATION: {
						apiEndpoint = process.env.REACT_APP_API_URL + "register3rdStep";
						requiredFields = ["pickup_type", "pickup_city", "pickup_school"];

						if (savedData["pickup_type"] === "יאסף בליווי אדם מבוגר מטעם המשפחה") {
							["pickup_contact_name", "pickup_contact_phone", "pickup_contact_closeness"].forEach((field) => {
								requiredFields.push(field);
							});
						}

						break;
					}

					case RegistrationSteps.HEALTH_INFORMATION: {
						apiEndpoint = process.env.REACT_APP_API_URL + "register4thStep";
						// requiredFields = ['healthy', 'contact_name', 'contact_gov_id', 'contact_phone', 'sensitivities', 'allergies', 'can_swim'];
						requiredFields = ["healthy", "contact_name", "contact_gov_id", "contact_phone", "can_swim"];

						break;
					}

					default: {
						alert("API endpoint could not be found because of an error.");

						break;
					}
				}

				if (!savedData) {
					alert("no saved data");

					return;
				}

				let invalidateFields = [];

				/**
				 * Make sure the student is older than 6.
				 */
				if (currentStep === RegistrationSteps.STUDENT_INFORMATION) {
					if (requiredFields.includes("birthdate")) {
						let today = new Date().getTime();
						let birthdate = new Date(savedData["birthdate"]).getTime();

						if (today - birthdate < 15768000000) {
							alert("לא ניתן לרשום ילד/ה מתחת לגיל 6.");

							return;
						}
					}
				}

				/**
				 * Make sure the user approved the pickup location.
				 */
				if (currentStep === RegistrationSteps.PICKUP_INFORMATION) {
					if (savedData && !savedData["pickup_agree"]) {
						invalidateFields.push("pickup_agree");
					}
				}

				/**
				 * Make sure the user accepted the ToS.
				 */
				if (currentStep === RegistrationSteps.HEALTH_INFORMATION) {
					if (savedData && !savedData["terms_agree"]) {
						invalidateFields.push("terms_agree");
					}
				}

				requiredFields.forEach((field) => {
					if (!savedData.hasOwnProperty(field)) {
						invalidateFields.push(field);
						console.log("invalidate field:", field);
					} else if (typeof savedData[field] !== "boolean" && typeof savedData[field] !== "number" && !savedData[field].length) {
						invalidateFields.push(field);
						console.error("invalid field", field, typeof savedData[field]);
					}
				});

				setInvalidFields(invalidateFields);

				if (invalidateFields.length) {
					return;
				}

				fetch(apiEndpoint, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-CSRF-TOKEN": fieldsData.token,
					},
					body: JSON.stringify({
						...savedData,
					}),
				})
					.then((res) => res.json())
					.then((res) => {
						if (!res.success) {
							console.log(res);

							if (res.errors) {
								let invalidateFields = [];

								Object.keys(res.errors).forEach((field) => {
									invalidateFields.push(field);

									console.log("invalidate:", field);

									console.error(field);
								});

								setInvalidFields(invalidateFields);
							}

							return;
						}

						res = res.data;
						let student_id = res.id;

						setSavedData({
							...res,
							student_id,
						});

						setCurrentStep(currentStep + 1);
					});

				break;
			}
			default: {
				alert("Unhandled");
				break;
			}
		}
	};

	const renderCurrentStepIndicator = () => {
		const steps = [
			{ label: "פרטי הילד", number: "01" },
			{ label: "יצירת קשר", number: "02" },
			{ label: "איסוף", number: "03" },
			{ label: "הצהרה", number: "04" },
			{ label: "סיכום", number: "05" },
		];

		return (
			<div className="current-step-indicator">
				{steps.map((step, index) => {
					let classes = "current-step-indicator__step";

					index += 2;

					if (index < currentStep) {
						classes += " current-step-indicator__step--done";
					} else if (index === currentStep) {
						classes += " current-step-indicator__step--current";
					}

					return (
						<div key={index} className={classes}>
							<span>{step.label}</span>
							<div className="current-step-indicator__step__number">
								<span>{step.number}</span>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	let fieldErrorMessage = "אנא וודאו כי השדה מכיל את המידע הנדרש.";

	const maybeRenderFieldError = (field) => {
		if (invalidFields && invalidFields.includes(field.name)) {
			return <span className="text-xs leading-none text-red-700">{fieldErrorMessage}</span>;
		}

		return null;
	};

	const renderFormField = (field) => {
		if (field.type === "select") {
			return (
				<select
					className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${invalidFields && invalidFields.includes(field.name) ? "border-red-600" : "border-transparent"}`}
					name={field.name}
					onChange={onInputChange}
					value={savedData && savedData.hasOwnProperty(field.name) ? savedData[field.name] : ""}>
					{field.placeholder && typeof field.placeholder === "boolean" && <option value={null}>-</option>}
					{field.placeholder && typeof field.placeholder === "string" && <option value={null}>{field.placeholder}</option>}
					{field.options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			);
		} else if (field.type === "phone") {
			return (
				<div className="flex flex-row-reverse items-center gap-4">
					<div className="w-1/4">
						<select
							className="px-4 py-3 rounded-2xl w-full text-black text-sm"
							name="emergency_phone_prefix"
							type="text"
							onChange={(e) => {
								onPhoneNumberChange(field.name, "prefix", e.target.value);
							}}
							value={getPhoneNumber(field.name, "prefix") || ""}>
							<option value="">בחר/י</option>
							{phonePrefixes.map((prefix) => {
								return (
									<option key={prefix} value={prefix}>
										{prefix}
									</option>
								);
							})}
						</select>
					</div>
					<div className="w-3/4">
						<input
							className="px-4 py-3 rounded-2xl w-full text-black text-sm"
							name="emergency_phone_number"
							type="text"
							onChange={(e) => {
								onPhoneNumberChange(field.name, "number", e.target.value);
							}}
							value={getPhoneNumber(field.name, "number") || ""}
						/>
					</div>
				</div>
			);
		}

		return <input className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${invalidFields && invalidFields.includes(field.name) ? "border-red-600" : "border-transparent"}`} name={field.name} type={field.type} onChange={(e) => onInputChange(e)} value={(savedData && savedData[field.name]) || ""} />;
	};

	const renderFormControl = (field, groupSize = 1) => {
		if (field.hasOwnProperty("condition")) {
			if ((typeof field.condition === "string" && !savedData[field.condition]) || (typeof field.condition === "boolean" && !field.condition)) {
				return null;
			}
		}

		if (field.type === "checkbox") {
			return (
				<div>
					<label className="block w-full text-right">
						{renderFormField(field)}
						<span dangerouslySetInnerHTML={{ __html: field.label }} />
					</label>
					{maybeRenderFieldError(field)}
				</div>
			);
		}

		return (
			<div className="w-full flex items-start gap-2">
				<div className={`w-full ${groupSize === 1 ? "lg:w-1/5" : groupSize === 2 ? "lg:w-3/12" : "lg:w-2/5"} flex items-center pt-3`}>
					<label className="font-semibold" htmlFor={field.name}>
						{field.label}
					</label>
				</div>
				<div className={`w-full ${groupSize === 1 ? "lg:w-4/5" : groupSize === 2 ? "lg:w-9/12" : "lg:w-3/5"}`}>
					{renderFormField(field)}
					{maybeRenderFieldError(field)}
				</div>
			</div>
		);
	};

	const renderFormObject = (field, index) => {
		if (typeof field === "object" && field.constructor === Array.prototype.constructor) {
			return (
				<div key={index} className="mb-4 flex gap-4">
					{field.map((field, index) => {
						return (
							<div key={index} className="w-full lg:w-1/2">
								{renderFormControl(field, field.groupSize || 3)}
							</div>
						);
					})}
				</div>
			);
		}

		return (
			<div key={index} className="mb-4">
				{renderFormControl(field, field.groupSize || (field.type === "phone" ? 2 : 1))}
				{field.subtitle && field.subtitle.length && <span className="block mt-1 text-sm text-black">{field.subtitle}</span>}
			</div>
		);
	};

	const renderCurrentStep = () => {
		/**
		 * Step 1: camp selection
		 */
		if (!currentStep || currentStep === RegistrationSteps.CAMP_SELECTION) {
			let camps = [];

			if (fieldsData && fieldsData.camps) {
				camps = fieldsData.camps.filter((camp) => camp.registrations_opened);
			}

			return <SelectCamp onCampSelected={onCampSelected} onContinue={nextStep} camps={camps} selectedCamp={selectedCamp} />;
		} else if (currentStep === RegistrationSteps.STUDENT_INFORMATION) {
			/**
			 * Step 2: personal information
			 */
			return (
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
					{renderCurrentStepIndicator()}
					<StudentInformationForm renderFormObject={renderFormObject} />
					<div className="mt-4 text-right">
						<p>* השדה 'מספר כיתה' מייצג את מספר הכיתה בשכבה אליה הילד/ה רשום/ה. לדוגמה: אם הילד/ה בכיתה ג' 3, יש להזין בשדה את המספר 3.</p>
					</div>
					<div className="flex justify-between mt-8">
						<Button onClick={prevStep}>חזור</Button>
						<Button onClick={nextStep}>המשך</Button>
					</div>
				</div>
			);
		} else if (currentStep === RegistrationSteps.CONTACT_INFORMATION) {
			/**
			 * Step 3: contact information
			 */

			const workersCommittees = fieldsData?.workers_committees || [];

			return (
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
					{renderCurrentStepIndicator()}
					<ContactInformationForm renderFormObject={renderFormObject} renderFormControl={renderFormControl} workersCommittees={workersCommittees} savedData={savedData} onInputChange={onInputChange} />
					<div className="flex justify-between mt-8">
						<Button onClick={prevStep}>חזור</Button>
						<Button onClick={nextStep}>המשך</Button>
					</div>
				</div>
			);
		} else if (currentStep === RegistrationSteps.PICKUP_INFORMATION) {
			/**
			 * Step 4: pickup information
			 */
			return (
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
					{renderCurrentStepIndicator()}
					<PickupInformationForm renderFormObject={renderFormObject} savedData={savedData} cities={fieldsData.cities} onInputChange={onInputChange} invalidFields={invalidFields} />
					<div className="flex justify-between mt-8">
						<Button onClick={prevStep}>חזור</Button>
						<Button onClick={nextStep}>המשך</Button>
					</div>
				</div>
			);
		} else if (currentStep === RegistrationSteps.HEALTH_INFORMATION) {
			/**
			 * Step 5: statement
			 */
			return (
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
					{renderCurrentStepIndicator()}
					<HealthInformationForm renderFormObject={renderFormObject} selectedCamp={selectedCamp} cities={fieldsData.cities} savedData={savedData} onInputChange={onInputChange} invalidFields={invalidFields} />
					<div className="flex justify-between mt-8">
						<Button onClick={prevStep}>חזור</Button>
						<Button onClick={nextStep}>המשך</Button>
					</div>
				</div>
			);
		} else if (currentStep === RegistrationSteps.SUCCESS) {
			/**
			 * Step 6: payment
			 */
			return (
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl bg-opacity-20 bg-white text-white">
					<h2 className="text-3xl font-bold mb-4">ההרשמה בוצעה בהצלחה!</h2>
					<p className="mb-2">
						הורה יקר,
						<br />
						אנו שמחים להודיעך כי בקשתך להרשמת{" "}
						<strong>
							{savedData.first_name} {savedData.last_name}
						</strong>{" "}
						לקייטנת {savedData.camp && savedData.camp.name} נשלחה בהצלחה, ומודים לך על שיתוף הפעולה.
					</p>
					<p>
						ברגעים אלו נשלח אלייך מייל לכתובת המייל {savedData.email} עם הוראות לביצוע התשלום והשלמת ההרשמה לקייטנה.
						<br />
						אם אינך רואה את המייל בתוך מספר דקות, יש לבדוק בתיבת הספאם (דואר זבל).
					</p>
					<a href="/" className="inline-block mt-6 mb-4 rounded-3xl bg-blue-500 text-white py-3 px-6">
						חזרה לעמוד הבית
					</a>
				</div>
			);
		}

		return <div>Invalid step: {currentStep}</div>;
	};

	if (currentStep === RegistrationSteps.SUCCESS) {
		/**
		 * workers-committee don't need to pay for registrations.
		 */
		if (savedData?.order?.type === "workers-committee") {
			return <Navigate to={"/workers-committee/" + savedData?.order?.uid} replace={true} />;
		}

		if (!savedData || !savedData.order) {
			return;
		}

		let url = "/order-received/" + savedData.order.uid;

		return <Navigate to={url} replace={true} />;

		// let transactionKey = null;

		// if (savedData?.order?.transactions) {
		// 	savedData.order.transactions.forEach((transaction) => {
		// 		if (!transactionKey && transaction.type === "advance-payment" && !transaction.is_paid) {
		// 			transactionKey = transaction.uid;
		// 		}
		// 	});
		// }

		// let url = "/checkout/" + savedData?.order?.uid + "/transaction/" + transactionKey;

		// return <Navigate to={url} replace={true} />;
	}

	return <Layout>{fieldsData ? renderCurrentStep() : <div>Loading</div>}</Layout>;
};

export default Registration;

import React from "react";

const StepFour = ({ renderFormObject, savedData, onInputChange, invalidFields }) => {
	let isMale = savedData && savedData.gender === "male";
	let healthStatement = (isMale ? "בני" : "בתי") + ", " + savedData.first_name + " " + savedData.last_name + ":";
	let healthyLabel = (isMale ? "בריא" : "בריאה") + " לחלוטין " + (isMale ? "ואינו סובל" : "ואינה סובלת") + " מבעיות בריאותיות שעשויות להגביל את " + (isMale ? "השתתפותו" : "השתתפותה") + " בקייטנה";
	let notHealthyLabel = (isMale ? "סובל" : "סובלת") + " מבעיות בריאותיות שעשויות להגביל את " + (isMale ? "השתתפותו" : "השתתפותה") + " בפעילויות מסוימות של הקייטנה";

	let canSwimStatement = savedData.first_name + " " + savedData.last_name + " " + (isMale ? "יודע" : "יודעת") + " לשחות?";

	return (
		<div>
			<div className="grid grid-cols-2 gap-8 text-right mx-auto text-md w-full">
				{/* Temporarily */}

				<div className="col-span-2">
					<div className="text-right">
						<h3 className="text-2xl font-bold">הצהרת בריאות</h3>
						<p className="block mt-2">{healthStatement}</p>
					</div>
				</div>
				{/* <div className='col-start-1 col-span-2'>
                <div className='col-span-10 mb-4 flex items-center'>
                    <label className='font-bold' htmlFor="">אני מצהיר בזאת כי</label>
                </div>
            </div> */}
				<div className="col-span-1">
					{/* <div className='mb-4'>
                    <span>{healthStatement}</span>
                    <label className="block">
                        <input
                            type="radio"
                            name="healthy"
                            value="true"
                            onChange={e => onInputChange(e)}
                            checked={savedData && savedData.hasOwnProperty('healthy') && savedData.healthy}
                        />
                        <span>{healthyLabel}</span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            name="healthy"
                            value="false"
                            onChange={e => onInputChange(e)}
                            checked={savedData && savedData.hasOwnProperty('healthy') && !savedData.healthy}
                        />
                        <span>{notHealthyLabel}</span>
                    </label>
                    {invalidFields && invalidFields.includes('healthy') && (
                        <div className='text-red-600 text-xs'>
                            <span>נא לבחור אחת מהאפשרויות</span>
                        </div>
                    )}
                </div> */}

					{[
						{
							name: "healthy",
							label: "בריא לחלוטין",
							type: "select",
							placeholder: true,
							options: [
								{ value: false, label: "לא" },
								{ value: true, label: "כן" },
							],
						},
						{
							name: "has_sensitivities",
							label: "רגישויות",
							type: "select",
							placeholder: true,
							options: [
								{ value: false, label: "לא" },
								{ value: true, label: "כן" },
							],
						},
						{ name: "sensitivities", label: "פירוט רגישויות", type: "text", condition: "has_sensitivities", groupSize: 2 },
						{
							name: "has_allergies",
							label: "אלרגיות",
							type: "select",
							placeholder: true,
							options: [
								{ value: false, label: "לא" },
								{ value: true, label: "כן" },
							],
						},
						{ name: "allergies", label: "פירוט אלרגיות", type: "text", condition: "has_allergies", groupSize: 2 },
						{
							name: "can_swim",
							label: "יודע לשחות",
							placeholder: canSwimStatement,
							type: "select",
							options: [
								{ value: false, label: "לא" },
								{ value: true, label: "כן" },
							],
						},
					].map((field, index) => {
						return renderFormObject(field, index);
					})}
				</div>

				<div className="col-span-1">
					{[
						{ name: "contact_name", label: "שם ההורה", type: "text" },
						{ name: "contact_gov_id", label: "ת.ז ההורה", type: "text" },
						{ name: "contact_phone", label: "טלפון ההורה", type: "phone" },
					].map((field, index) => {
						return renderFormObject(field, index);
					})}
				</div>
			</div>

			<div className="mt-0 text-right">
				<div className="mt-2">
					{renderFormObject({
						type: "checkbox",
						name: "terms_agree",
						label: 'אני מאשר/ת שקראתי את <a href="https://www.funkid-k.com/תקנון/" target="_blank" style="text-decoration: underline;">תנאי השימוש ותקנון הקייטנה</a> ואני מסכים/ה להם',
						required: true,
						placeholder: false,
					})}
				</div>
			</div>
		</div>
	);
};

export default StepFour;

import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";

import logo from "../storage/logo.png";

const WorkersCommitteeThankyou = () => {
	const { orderKey } = useParams();

	const [orderData, setOrderData] = useState(null);

	/**
	 * Component initialized.
	 */
	useEffect(() => {
		const fetchOrderData = (orderUid) => {
			let url = process.env.REACT_APP_API_URL + "order/" + orderUid;

			fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then((res) => res.json())
				.then((res) => {
					if (!res.success) {
						alert("error!");
						return;
					}

					setOrderData(res.data);
				});
		};

		if (orderKey) {
			fetchOrderData(orderKey);
		}
	}, [orderKey]);

	if (!orderData) {
		return <></>;
	} else if (orderData.transactions && orderData.transactions.length) {
		return <Navigate to="https://www.funkid-k.com/" replace={true} />;
	}

	const { student } = orderData;

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<header className="absolute top-[-67.5px] w-full">
						<img src={logo} alt="logo" className="mx-auto" />
					</header>
					<div className="px-4">
						<strong className="text-2xl font-semibold mb-2 block">הרשמה לקייטנה</strong>
						<p className="text-lg font-light mb-4">
							שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
							<br />
							אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת אקשן על גלגלים.
						</p>
						<p>לידיעתכם, הרישום לקייטנה מותנה באישור ועד העובדים שציינתם בשלבי ההרשמה.</p>
						<p className="block mt-4 text-sm">
							זו הזדמנות להזכירכם לפרגן לחברים ולשתפם בחווית הקייטנה שלנו.
							<br />
							בכל שאלה - אנחנו כאן בשבילכם בטלפון{" "}
							<a href="tel:0544781055" className="text-blue-400">
								054-4781055
							</a>
							, או באתר{" "}
							<a href="https://www.funkid-k.com" target="_blank" rel="noreferrer" className="text-blue-400">
								www.funkid-k.com
							</a>
						</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default WorkersCommitteeThankyou;

import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";

import logo from "../storage/logo.png";

const Checkout = () => {
	const { orderKey, transactionKey } = useParams();

	const [paymentMethod, setPaymentMethod] = useState("creditcard");
	const [paymentMethodSaved, setPaymentMethodSaved] = useState(false);

	const [transactionData, setTransactionData] = useState(null);
	const [paymentPageURL, setPaymentPageURL] = useState(null);

	/**
	 * Component initialized.
	 */
	useEffect(() => {
		if (orderKey && transactionKey) {
			fetchTransactionData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Transaction data changed.
	 */
	useEffect(() => {
		if (!transactionData) {
			return;
		}

		const { page_request_uid } = transactionData;

		setPaymentPageURL("https://paymentsdev.payplus.co.il/" + page_request_uid);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionData]);

	/**
	 * Functions.
	 */
	const fetchTransactionData = () => {
		let url = process.env.REACT_APP_API_URL + "order/" + orderKey + "/transaction/" + transactionKey;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					alert("error!");
					return;
				}

				setTransactionData(res.data);

				if (res?.data?.order) {
					setPaymentMethod(res.data.order.method);
				}
			});
	};

	const onPaymentMethodChanged = (e) => {
		setPaymentMethod(e.target.value);
	};

	const savePaymentMethod = () => {
		let url = process.env.REACT_APP_API_URL + "order/" + orderKey + "/method";

		fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				payment_method: paymentMethod,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (!res.success) {
					alert("error!");
					return;
				}

				setPaymentMethodSaved(true);
			});
	};

	/**
	 * Loading screen.
	 */
	if (!transactionData) {
		return (
			<Layout>
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl">
					<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
						<div className="px-4">
							<header className="absolute top-[-67.5px] w-full">
								<img src={logo} alt="logo" className="mx-auto" />
							</header>
							<div className="py-12">
								<span>העמוד בטעינה</span>
							</div>
						</div>
						{transactionData && !paymentMethodSaved && renderPaymentMethod()}
					</div>
				</div>
			</Layout>
		);
	}

	const { order, type } = transactionData;
	const { student } = order;

	/**
	 * Transaction already paid.
	 */
	if (transactionData?.is_paid) {
		let thankyouPage = "/order/" + transactionData.order.uid + "/thankyou/";

		return <Navigate to={thankyouPage} replace={true} />;
	} else if (transactionData?.order?.type === "workers-committee") {
		/**
		 * The order should not be paid in some cases.
		 * 1. The order belongs to workers committee group.
		 */
		return <div>workers-committee.</div>;
	}

	const renderWelcomeMessage = () => {
		/**
		 * Advance payment's welcome message.
		 */
		if (type === "advance-payment") {
			return (
				<p className="text-md font-light mb-4">
					שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
					<br />
					אנחנו שמחים על החלטתכם להרשם לקייטנת אקשן על גלגלים.
					<br />
					<br />
					על מנת לשריין את מיקומו של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> בקייטנה, עליכם להסדיר את תשלום המקדמה.
				</p>
			);
		} else if (type === "full-payment") {
			/**
			 * Full payment's welcome message.
			 */
			return (
				<p className="text-md font-light mb-4">
					שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
					<br />
					<strong className="font-bold">תשלום המקדמה הושלם בהצלחה.</strong>
					<br />
					אנחנו שמחים על החלטתכם להשלים את התשלום עבור ההרשמה של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת אקשן על גלגלים.
				</p>
			);
		}

		return null;
	};

	const renderPriceLabel = () => {
		let { discounted_price, total_price } = order;

		if (!discounted_price) {
			return <span>{total_price}₪</span>;
		}

		return (
			<span>
				<span className="text-gray-500 line-through">{total_price}₪</span>
				<span className="ml-2">{discounted_price}₪</span>
			</span>
		);
	};

	const rednerPaidPriceLabel = () => {
		let { discounted_price, total_price } = order;

		let total_paid = total_price - order.total_unpaid;

		return <span className="text-green-400">{total_paid}₪</span>;
	};

	const renderTable = () => {
		/**
		 * The table is displayed only on the full payment page.
		 */
		if (type !== "full-payment") {
			return;
		}

		return (
			<div className="bg-gray-100 border border-gray-200 w-full sm:w-3/4 mx-auto mt-6 mb-8 py-4 px-4 rounded-md">
				<table className="w-full text-center table-auto">
					<thead>
						<tr>
							<th>מספר הרשמה</th>
							<th>שם הילד/ה</th>
							<th>שם הקייטנה</th>
							<th>נקודת איסוף</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{student.id}</td>
							<td>{student.first_name + " " + student.last_name}</td>
							<td>{order.camp.name}</td>
							<td>
								{student.pickup_information.pickup_school}, {student.pickup_information.pickup_city}
							</td>
						</tr>
					</tbody>
				</table>
				<hr className="mt-3" />
				<table className="w-full text-center table-auto mt-3">
					<thead>
						<tr>
							<th>סה"כ לתשלום</th>
							<th>שולם</th>
							<th>יתרה לתשלום</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{ direction: "ltr" }}>{renderPriceLabel()}</td>
							<td style={{ direction: "ltr" }}>{rednerPaidPriceLabel()}</td>
							<td style={{ direction: "ltr" }}>{order.total_unpaid}₪</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	};

	const renderPaymentMethod = () => {
		if (paymentMethod === "creditcard" || paymentMethod === null) {
			return paymentPageURL ? <iframe title="AAG Payment" src={paymentPageURL} className="tw-block tw-w-full tw-h-auto" width="100%" style={{ aspectRatio: "16 / 12" }}></iframe> : null;
		} else if (paymentMethod === "cash") {
			return (
				<div className="w-full sm:w-3/4 mx-auto py-4 px-4 text-right">
					<div className="mb-4">
						<p>תודה שבחרת לשלם במזומן. יש להעביר לרכז האוטובוס את הסכום במעטפה ביום הראשון של הקייטנה, עם שם הילד/ה ומספר הרשמה.</p>
					</div>
					<button className="block w-full py-4 px-6 bg-black text-white rounded-2xl" onClick={(e) => savePaymentMethod(paymentMethod)}>
						שמירת אמצעי תשלום
					</button>
				</div>
			);
		} else if (paymentMethod === "check") {
			return (
				<div className="w-full sm:w-3/4 mx-auto py-4 px-4 text-right">
					<div className="mb-4">
						<p>
							תודה שבחרת לשלם בהמחאות.
							<br />
							את הסכום לתשלום ניתן לחלק לשלושה תשלומים ולתאריכים: 1.7.2023, 1.8.2023, 1.9.2023 בלבד.
							<br />
							את ההמחאות יש לרשום לפקודת "טי. אייץ' השקעות בע"מ".
						</p>
					</div>
					<button className="block w-full py-4 px-6 bg-black text-white rounded-2xl" onClick={(e) => savePaymentMethod(paymentMethod)}>
						שמירת אמצעי תשלום
					</button>
				</div>
			);
		}

		return null;
	};

	const renderSubtitle = () => {
		/**
		 * Advance payment's subtitle.
		 */
		if (type === "advance-payment") {
			return <strong className="text-2xl font-semibold mb-2 block">תשלום מקדמה</strong>;
		} else if (type === "full-payment") {
			return (
				// <strong className='text-2xl font-semibold mb-2 block'>תשלום מלא</strong>
				<div className="w-full sm:w-3/4 mx-auto text-right">
					<span className="block mb-2 font-bold">בחר/י אמצעי תשלום</span>
					<div className="flex items-center">
						<select className="ml-2 bg-gray-200 text-gray-800 font-semibold py-4 px-6 w-full rounded-2xl" value={paymentMethod} onChange={onPaymentMethodChanged}>
							<option value="creditcard">כרטיס אשראי</option>
							<option value="cash">מזומן</option>
							<option value="check">צ'ק</option>
						</select>
					</div>
				</div>
			);
		}

		return null;
	};

	const renderBody = () => {
		if (paymentMethodSaved) {
			return <span>אמצעי התשלום נשמר בהצלחה.</span>;
		}

		return (
			<div>
				<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
				{renderWelcomeMessage()}
				{renderTable()}
				{renderSubtitle()}
			</div>
		);
	};

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<div className="px-4">
						<header className="absolute top-[-67.5px] w-full">
							<img src={logo} alt="logo" className="mx-auto" />
						</header>
						{renderBody()}
					</div>
					{!paymentMethodSaved && renderPaymentMethod()}
				</div>
			</div>
		</Layout>
	);
};

export default Checkout;
